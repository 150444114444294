import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
// LEE {
  // import BlazeSlider from 'blaze-slider'
  import 'blaze-slider/dist/blaze.css'
  // }


const BSlider = props => {
  const data = useStaticQuery(graphql`
    query BSliderQuery {
        bsliderJson {
          email
          image1
          image2
          image3
          image4
          image5
          image6
          image7
        }
    }
   `);
  // alert('Here, Prepare things needed for BSlider Info.');
  


  return (
<div class="blaze-slider">
<div class="blaze-container">
  <div class="blaze-track-container">
    <div class="blaze-track">
      <div><img class="slide-image" src={data.bsliderJson.image1}/></div>
      <div><img class="slide-imagew" src={data.bsliderJson.image2}/></div>
      <div><img class="slide-imagew" src={data.bsliderJson.image3}/></div>
      <div><img class="slide-imagew" src={data.bsliderJson.image4}/></div>
      <div><img class="slide-imagew" src={data.bsliderJson.image5}/></div>
      <div><img class="slide-imagew" src={data.bsliderJson.image6}/></div>
      <div><img class="slide-imagew" src={data.bsliderJson.image7}/></div>
    </div>
  </div>
  <div class="controls">
  <button class="blaze-prev">previous</button>
  <div class="blaze-pagination"></div>
  <button class="blaze-next">next</button>
  </div>
</div>
</div>
  );
};

export default BSlider;
