// import React from 'react';
import React, { useEffect } from "react";
//
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Call from '../components/Call';
import BSlider from '../components/BSlider';

// LEE {
  import BlazeSlider from 'blaze-slider'
  //import 'blaze-slider/dist/blaze.css'

  // }

const Home = props => {
  const intro = props.data.intro;
  const site = props.data.site.siteMetadata;

  const introImageClasses = `intro-image ${intro.frontmatter.intro_image_absolute && 'intro-image-absolute'} ${intro.frontmatter.intro_image_hide_on_mobile && 'intro-image-hide-mobile'}`;

  // alert('Here, Prepare things needed before index html rendering goes on.');
  useEffect(() => {
    // alert('Here, useEffect called');
    const el = document.querySelector('.blaze-slider')
    // new BlazeSlider(el);
    new BlazeSlider(el, {
      all: {
        enableAutoplay: false,
        autoplayInterval: 2000,
        transitionDuration: 300,
        slidesToShow: 3,
      },
      '(max-width: 900px)': {
        slidesToShow: 2,
      },
      '(max-width: 500px)': {
        slidesToShow: 1,
      },
    })
    return () => {
      // alert('Here, cleanUp 4 useEffect');
    };
  },[]);


  return (
    <Layout bodyClass="page-home">
      <SEO title={site.title} />
      <Helmet>
        <meta
          name="description"
          content="こんにちは！こちらは、しもやピアノ教室のホームページです。"
        />
      </Helmet>

      <div className="intro">
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-12 col-md-7 col-lg-6 order-2 order-md-1">
              <div dangerouslySetInnerHTML={{ __html: intro.html }} />
              <Call showButton />
            </div>
            {intro.frontmatter.intro_image && (
              <div className="col-12 col-md-5 col-lg-6 order-1 order-md-2 position-relative">
                <img alt={intro.frontmatter.title} className={introImageClasses} src={intro.frontmatter.intro_image} />
              </div>
            )}
          </div>
        </div>
      </div>
      <BSlider /><br/><br/><br/>
    </Layout>
  );
};

export const query = graphql`
  query {
    intro: markdownRemark(
      fileAbsolutePath: {regex: "/content/index.md/"}
    ) {
        html
        frontmatter {
          intro_image
          intro_image_absolute
          intro_image_hide_on_mobile
          title
        }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default Home;
